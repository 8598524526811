const handleClickAway = (evt) => {
  const navLink = document.querySelector(".HeaderNavLink.is-open");
  const clickedInside = navLink ? navLink.contains(evt.target) : false;
  if (!clickedInside) {
    navLink.classList.remove("is-open");
    document.removeEventListener("mouseup", handleClickAway);
  }
};

const toggleSubMenu = (ev) => {
  const navLink = event.target.parentNode;
  navLink.classList.toggle("is-open");

  const closeSubMenu = () => {
    navLink.classList.remove("is-open");
    document.removeEventListener("mouseup", handleClickAway);
    navLink
      .querySelector(".HeaderNavLink-back")
      .removeEventListener("click", closeSubMenu);
  };

  if (navLink.classList.contains("is-open")) {
    document.addEventListener("mouseup", handleClickAway);
    navLink
      .querySelector(".HeaderNavLink-back")
      .addEventListener("click", closeSubMenu);
  } else {
    document.removeEventListener("mouseup", handleClickAway);
    navLink
      .querySelector(".HeaderNavLink-back")
      .removeEventListener("click", closeSubMenu);
  }
};

const toggleMobileNav = (ev) => {
  document.querySelector(".Header").classList.toggle("is-toggled");
  document.querySelector(".Header-burgerToggle").classList.toggle("is-active");
};

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("button.HeaderNavLink-link").forEach((link) => {
    link.addEventListener("click", toggleSubMenu);
  });
  document
    .querySelector(".Header-burgerToggle")
    .addEventListener("click", toggleMobileNav);
});
