document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".Contact-map").forEach((container) => {
    const lat = parseFloat(container.getAttribute("data-lat")) || 0;
    const lng = parseFloat(container.getAttribute("data-lng")) || 0;

    const lat_lon = {
      lon: lng,
      lat: lat,
    };

    const map = new mapboxgl.Map({
      style: "mapbox://styles/t-ewert/cksnl4ajg37yf17oo0kkih666",
      container: container,
      center: lat_lon,
      interactive: true,
      zoom: 16,
    });

    map.scrollZoom.disable();
    map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      }),
      "top-right"
    );

    const marker = new mapboxgl.Marker({
      color: "#0052a3",
    })
      .setLngLat(lat_lon)
      .addTo(map);
  });
});
